import React from "react";
import { Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import pricesArray from "../constant/prices";
import fundsArray from "../constant/funds";
import { useTranslation } from "react-i18next";

function Prices() {
  const { t, i18n } = useTranslation();
  const lng = i18n.language;
  const lang = lng.split("-")[0];

  const renderTables = () => {
    return pricesArray.map((item, index) => {
      return (
        <div key={`table-${index}`} style={{ marginTop: 20 }}>
          {item.categoryTitle && item.categoryTitle[lang] && (
            <h3>{item.categoryTitle[lang]}</h3>
          )}
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style={{
              boxShadow: "1px 2px 1px 1px #888888",
              margin: isMobile ? 0 : 20,
              marginTop: isMobile ? 20 : 0,
              marginBottom: isMobile ? 20 : 0,
              width: "100%",
            }}
          >
            <Table>
              <thead>
                <tr>
                  <th>{item.headerTitle[lang]}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {item.items.map((categoryItem, idx) => (
                  <tr key={`item-${index}-${idx}`} className="col-md-3">
                    <td style={{ width: "70%" }}>{categoryItem.name[lang]}</td>
                    <td
                      className="col-md-3"
                      style={{
                        fontWeight: categoryItem.isBold ? "bold" : "normal",
                      }}
                    >
                      {categoryItem.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    });
  };

  const renderFunds = () => (
    <div
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{
        boxShadow: "1px 2px 1px 1px #888888",
        margin: isMobile ? 0 : 20,
        marginTop: isMobile ? 20 : 0,
        marginBottom: isMobile ? 20 : 0,
        width: "100%",
      }}
    >
      <Table>
        <thead>
          <tr>
            <th>{t("healthFunds")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fundsArray.map((item, index) => (
            <tr key={`fund-${index}`} className="col-md-3">
              <td
                style={{
                  width: "70%",
                  padding: isMobile ? ".35rem" : undefined,
                }}
              >
                <img className="fundImage" alt={item.title} src={item.img} />
              </td>
              <td
                style={{
                  padding: isMobile ? ".35rem" : undefined,
                  verticalAlign: isMobile ? "middle" : "top",
                }}
              >
                {item.title}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );

  return (
    <div className="container">
      <div
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style={{
          boxShadow: "1px 2px 1px 1px #888888",
          margin: isMobile ? 0 : 20,
          marginTop: isMobile ? 20 : 0,
          marginBottom: isMobile ? 20 : 0,
          width: "100%",
        }}
      ></div>
      {/*      <Table>
        <thead>
          <tr>
            <th>Feltöltés alatt!</th>
            <th></th>
          </tr>
        </thead>
      </Table> */}
      {renderTables()}
      {renderFunds()}
    </div>
  );
}

export default Prices;
