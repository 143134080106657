/**
 * SVG Import Helper
 *
 * This file provides a utility function to handle SVG imports properly
 * to prevent the [object Module] issue when SVGs are loaded.
 */

export function getSvgUrl(svg) {
  // If the import is already a string URL, just return it
  if (typeof svg === "string") {
    return svg;
  }

  // For webpack 5 and newer bundlers that use asset modules
  if (svg && svg.default) {
    return svg.default;
  }

  // For older bundlers using file-loader
  return svg;
}
