import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const MobileMenu = ({ closeMenu }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        backgroundColor: "#f1f8f9",
        color: "#53b4df",
        display: "flex",
        flexDirection: "column",
        fontSize: 14,
        minHeight: "100%",
      }}
    >
      <FontAwesomeIcon
        size="lg"
        icon={faTimesCircle}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          marginLeft: 10,
          marginTop: 10,
        }}
        onClick={closeMenu}
        color="#53b4df"
      />
      <img
        style={{
          height: 80,
          width: 160,
          margin: "auto",
          marginBottom: 10,
          marginTop: 10,
        }}
        alt="logo"
        src={require("../images/logo.png")}
      />
      <ul style={{ listStyleType: "none" }}>
        <li>
          <Link to="/">{t("homepage")}</Link>
        </li>
        <li>
          <Link to="/services/fogaszat">{t("benefits")}</Link>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <Link to="/services/fogaszat">{t("dentistry")}</Link>
              <ul>
                <li>
                  <Link to="/services/fogtomes">{t("toothFillings")}</Link>
                </li>
                <li>
                  <Link to="/services/fogko">{t("scaleRemoval")}</Link>
                </li>
                <li>
                  <Link to="/services/inlay">{t("inlay")}</Link>
                </li>
                <li>
                  <Link to="/services/korona">{t("crown")}</Link>
                </li>
                <li>
                  <Link to="/services/endodoncia">{t("endodontics")}</Link>
                </li>
                <li>
                  <Link to="/services/korona">{t("restorations")}</Link>
                </li>
                <li>
                  <Link to="/services/fogfeherites">{t("whitening")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/services/rontgen">{t("xray")}</Link>
              <ul>
                <li>
                  <Link to="/services/panoramartg">{t("digitalPanoXray")}</Link>
                </li>
                <li>
                  <Link to="/services/telertg">{t("digitalFullXray")}</Link>
                </li>
                <li>
                  <Link to="/services/ct">{t("digitalPeriapicalXray")}</Link>
                </li>
                <li>
                  <Link to="/services/tmjct">{t("tmjCT")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/services/szajsebeszet">{t("oralSurgery")}</Link>
            </li>
            <li>
              <Link to="/services/fogszabalyozas">{t("orthodontics")}</Link>
            </li>
            <li>
              <Link to="/services/parodontoligia">{t("periodontics")}</Link>
            </li>
            <li>
              <Link to="/services/homeopatia">{t("homeopathy")}</Link>
            </li>
            <li>
              <Link to="/services/ortopedia">{t("orthopedy")}</Link>
            </li>
            <li>
              <Link to="/services/borgyogyaszat">{t("dermatology")}</Link>
            </li>
            <li>
              <Link to="/services/urulogia">{t("urology")}</Link>
            </li>
            <li>
              <Link to="/services/gyneurologia">{t("childNeurology")}</Link>
            </li>
            <li>
              <Link to="/services/uzemorvos">{t("occupationalPhysician")}</Link>
            </li>
            <li>
              <Link to="/services/vendegszobak"> {t("guestrooms")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/doctors">{t("ourDoctors")}</Link>
        </li>

        <li>
          <Link to="/prices">{t("priceList")}</Link>
        </li>
        <li>
          <a
            href="http://caminomozgaskozpont.hu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Camino
          </a>
        </li>
        <li>
          <Link to="/contacts">{t("contacts")}</Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
